// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Estilo para o Container do WhatsApp */
.whatsapp-container {
  position: fixed;
  bottom: 20px; /* Alinha a 20px da parte inferior da tela */
  right: 20px; /* Alinha a 20px da parte direita da tela */
  z-index: 9999; /* Garante que o botão fique acima de outros elementos */
  animation: bounce 1s infinite; /* Animação de pulo */
}

/* Estilo do botão do WhatsApp */
.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25d366; /* Cor do WhatsApp */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 30px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #128c7e; /* Cor ao passar o mouse */
}

/* Animação de pulo */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0); /* Posição inicial e final */
  }
  50% {
    transform: translateY(-10px); /* Pulo no meio da animação */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/WhatsappButton.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;EACE,eAAe;EACf,YAAY,EAAE,4CAA4C;EAC1D,WAAW,EAAE,2CAA2C;EACxD,aAAa,EAAE,wDAAwD;EACvE,6BAA6B,EAAE,qBAAqB;AACtD;;AAEA,gCAAgC;AAChC;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB,EAAE,oBAAoB;EAC/C,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,2CAA2C;EAC3C,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB,EAAE,0BAA0B;AACvD;;AAEA,qBAAqB;AACrB;EACE;IACE,wBAAwB,EAAE,4BAA4B;EACxD;EACA;IACE,4BAA4B,EAAE,6BAA6B;EAC7D;AACF","sourcesContent":["/* Estilo para o Container do WhatsApp */\n.whatsapp-container {\n  position: fixed;\n  bottom: 20px; /* Alinha a 20px da parte inferior da tela */\n  right: 20px; /* Alinha a 20px da parte direita da tela */\n  z-index: 9999; /* Garante que o botão fique acima de outros elementos */\n  animation: bounce 1s infinite; /* Animação de pulo */\n}\n\n/* Estilo do botão do WhatsApp */\n.whatsapp-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #25d366; /* Cor do WhatsApp */\n  width: 60px;\n  height: 60px;\n  border-radius: 50%;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);\n  color: white;\n  font-size: 30px;\n  text-decoration: none;\n  transition: background-color 0.3s ease;\n}\n\n.whatsapp-button:hover {\n  background-color: #128c7e; /* Cor ao passar o mouse */\n}\n\n/* Animação de pulo */\n@keyframes bounce {\n  0%, 100% {\n    transform: translateY(0); /* Posição inicial e final */\n  }\n  50% {\n    transform: translateY(-10px); /* Pulo no meio da animação */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
