import React, { useState, useEffect } from "react";
import "./Slider.css";

interface Slide {
  image: string;
  leftText: string;
  rightText: string;
}

interface SliderProps {
  slides: Slide[];
}

const Slider: React.FC<SliderProps> = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const handleNext = (): void => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = (): void => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 3000); // Troca a cada 3 segundos
    return () => clearInterval(interval); // Limpa o intervalo ao desmontar
  }, []);

  return (
    <div className="slider" role="region" aria-label="Image slider">
      <button
        className="slider-button prev"
        onClick={handlePrev}
        aria-label="Slide anterior"
      >
        &#8249;
      </button>
      <div className="slide">
        <img
          src={slides[currentSlide].image}
          alt={`Slide ${currentSlide + 1}`}
        />
        <div className="text left">{slides[currentSlide].leftText}</div>
        <div className="text right">{slides[currentSlide].rightText}</div>
      </div>
      <button
        className="slider-button next"
        onClick={handleNext}
        aria-label="Próximo slide"
      >
        &#8250;
      </button>
      <div className="dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
            aria-label={`Ir para slide ${index + 1}`}
            role="button"
            tabIndex={0} // Permite navegação por teclado
            onKeyPress={(e) => e.key === 'Enter' && setCurrentSlide(index)} // Ação ao pressionar Enter
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;