import React, { useState } from "react";
import "./Slider.css";

interface Slide {
  image: string;
  leftText: string;
  rightText: string;
}

interface SliderProps {
  slides: Slide[];
}

const Slider: React.FC<SliderProps> = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div className="slider">
      <button className="slider-button prev" onClick={handlePrev}>
        &#8249;
      </button>
      <div className="slide">
        <img src={slides[currentSlide].image} alt={`Slide ${currentSlide + 1}`} />
        <div className="text left">{slides[currentSlide].leftText}</div>
        <div className="text right">{slides[currentSlide].rightText}</div>
      </div>
      <button className="slider-button next" onClick={handleNext}>
        &#8250;
      </button>
      <div className="dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
