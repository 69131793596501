import React from "react";
import "./ProfileCard.css";

interface SocialLinks {
  linkedin: string;
  youtube: string;
  facebook: string;
  instagram: string;
}

interface ProfileCardProps {
  name: string;
  title: string;
  description: string;
  yearsExperience: number;
  completedProjects: number;
  technologies: number;
  codesWritten: number;
  photo: string;
  cvLink: string;
  socialLinks: SocialLinks;
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name,
  title,
  description,
  yearsExperience,
  completedProjects,
  technologies,
  codesWritten,
  photo,
  cvLink,
  socialLinks,
}) => {
  return (
    <div className="profile-card">
      <div className="profile-photo">
        <img src={photo} alt={`Foto de ${name}`} />
      </div>
      <h1>{name}</h1>
      <h2>{title}</h2>
      <p>{description}</p>
      <a href={cvLink} className="download-cv" target="_blank" rel="noopener noreferrer">
        Download CV
      </a>
      <div className="social-icons">
        <a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href={socialLinks.youtube} target="_blank" rel="noopener noreferrer">
          <i className="fab fa-youtube"></i>
        </a>
        <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
        <a href={socialLinks.instagram} target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
      </div>
      <div className="stats">
        <div>
          <h3>{yearsExperience}</h3>
          <p>Anos de experiência</p>
        </div>
        <div>
          <h3>{completedProjects}</h3>
          <p>Projects Completos</p>
        </div>
        <div>
          <h3>{technologies}</h3>
          <p>Tecnologias Estudadas</p>
        </div>
        <div>
          <h3>{codesWritten}</h3>
          <p>Códigos Feitos</p>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
