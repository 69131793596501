import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
       <div className="Footer-dir">
        <p>BroscoTech.com.br - Todos os direitos reservados © 2024</p>
        </div>
    <div className="footer">
      <div className="footer-content">
        {/* Primeira camada: Links de Política e Termos */}
        <div className="footer-links">
          <div>
            <h3>Informações</h3>
            <a href="/politica-privacidade">Política de Privacidade</a>
            <a href="/termos-de-uso">Termos de Uso</a>
          </div>
          
          {/* Segunda camada: Redes Sociais */}
          <div>
            <h3>Redes Sociais</h3>
            <div className="fsocial-icons">
              <div className="Social-hor">
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
              </div>
            </div>
          </div>
          
          {/* Terceira camada: Contato */}
          <div>
            <h3>Contato</h3>
            <div className="footer-telefone">
              <i className="fas fa-phone"></i>
              <a href="tel:+1234567890"><h3>+55 (41) 991747318</h3></a>
            </div>
          </div>
        </div>
      </div>
         
    </div>


     
    
    </footer>
  );
};

export default Footer;
