import React from 'react';
import logo from './Logo.png';
import './App.css';
import Footer from './Footer';
import Menu from '../components/Menu';
import CompactLoginMenu from './CompactLoginMenu';
import ProfileCard from '../components/ProfileCard';
import photo from '../App/assets/RoggerC.png';
import Slider from '../components/Slider'; 
import Slide1 from './assets/Slide1.png';
import Slide2 from './assets/Slide2.png';
import Slide3 from './assets/Slide1.png';
import Slide4 from './assets/Slide1.png';
import PrivacyModal from '../components/PrivacyModal';
import { Bubble } from "@typebot.io/react";




const slides = [
  {
    image: Slide1,
    leftText: "Encontre um Especialista em TI dedicado a transformar seu negócio com soluções inovadoras. Com experiência em desenvolvimento, infraestrutura e segurança, ele oferece suporte completo para otimizar processos e garantir a excelência digital.",
  },
  {
    image: Slide2,
    rightText: "Proteja seus dados com um Especialista em Segurança de TI, oferecendo soluções avançadas contra ameaças cibernéticas e garantindo a integridade dos seus sistemas.",
  },
  {
    image: Slide1,
    leftText: "Crie seu site com um Especialista em TI, oferecendo soluções personalizadas e design inovador para garantir uma presença online impactante e funcional.",
  },
  {
    image: Slide2,
    rightText: "Conte com um Especialista em TI para a construção de servidores robustos e personalizados, garantindo desempenho, segurança e alta disponibilidade para seu negócio.",
  },
];

function App() {
  return (

    <div className="App">
            <header className="App-header">
            <div className="Menu-out"><img src={logo} className="App-logo" alt="logo" style={{ width: '250px', height: 'auto' }} />
                <Menu />
                <CompactLoginMenu />
                <Bubble
                  typebot="https://bot.broscotech.com.br/broscotech-suport-rz3gl5s"
                  theme={{
                  button: {
                  backgroundColor: "#0042DA",
                  customIconSrc: "https://broscotech.com.br/favicon.ico",
                  },
                  }}
                />
            </div>
            </header >
            <Slider slides={slides} />       
            <h2 className="specialists-header">Nosso Especialista</h2>
              <ProfileCard
              name="Rogger Brosco"
              title="Especialista I.T & Software Developer"
              description="Sou um especialista altamente qualificado na montagem e estruturação de empresas, com experiência abrangente desde a criação de bancos de dados até o desenvolvimento de sites. Tenho domínio em diversas linguagens de programação e sou responsável pelo controle e otimização de sistemas ERP. Além disso, sou especialista em PFSENSE, PF2AD e implementações de soluções inovadoras como ChatBots, garantindo soluções completas e eficientes para o seu negócio."
              yearsExperience={10}
              completedProjects={150}
              technologies={50}
              codesWritten={5000}
              photo={photo} // Substitua com a URL real da foto
              cvLink="/curriculum.pdf" // Substitua com o link real do CV
              socialLinks={{
              linkedin: "https://linkedin.com/roggerbrosco",
              youtube: "https://youtube.com/",
              facebook: "https://facebook.com/roggerbrosco",
              instagram: "https://instagram.com/roggerando",
              }}
              />
              
    <PrivacyModal />
    <Footer />
    </div >
  
    

  );
}

export default App;