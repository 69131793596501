import React, { useState, useEffect } from 'react';
import './PrivacyModal.css'; // Importando o arquivo CSS para o modal

const PrivacyModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  // Verifica se o usuário já aceitou a política de privacidade
  useEffect(() => {
    const isAccepted = localStorage.getItem('privacyAccepted');
    if (!isAccepted) {
      setIsModalVisible(true); // Exibe o modal caso não tenha aceitado
    }
  }, []);

  // Função para aceitar a política de privacidade
  const acceptPrivacy = () => {
    localStorage.setItem('privacyAccepted', 'true');
    setIsModalVisible(false); // Fecha o modal após aceitar
  };

  // Função para fechar o modal sem aceitar
  const closeModal = () => {
    setIsModalVisible(false); // Fecha o modal
  };

  if (!isModalVisible) return null; // Se o modal não estiver visível, não renderiza nada

  return (
    <div className="privacy-modal-overlay">
      <div className="privacy-modal">
        <h2>Política de Privacidade</h2>
        <p>
          Ao continuar usando este site, você concorda com nossa política de
          privacidade. Por favor, leia os termos com atenção antes de prosseguir.
        </p>
        <div className="modal-buttons">
          <button className="accept-button" onClick={acceptPrivacy}>
            Aceitar
          </button>
          <button className="close-button" onClick={closeModal}>
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyModal;
